import styled, { css } from "styled-components";

export const ServiceItemStyled = styled.li`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  /* border: 1px solid rgba(255, 255, 255, 0.5); */
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 8px;
  cursor: pointer;

  ///
  padding-top: 4rem;
  padding-bottom: 4rem;

  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  position: relative;
  text-decoration: none;
  border-radius: 0.2rem;
  padding: 2rem;
  color: white;
  background: rgba(0, 0, 0, 0)
    linear-gradient(to right, rgb(212, 20, 90) 0%, rgb(251, 176, 59) 100%)
    repeat scroll 0% 0%;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s !important;

  background-position: center;
  background-size: cover !important;
  /* width: 325px; */
  width: 100%;
  height: 150px;
  padding: 4rem 0 rem;

  overflow: hidden;
  h2 {
    line-height: 1.2;
    margin-bottom: 1rem;
    text-transform: capitalize;
    font-size: 1.5rem;
    font-weight: 700;
  }

  p {
    margin-top: auto;
    font-size: 1rem;
    flex-grow: 1;
    width: 293px;
    /* white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
      rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  }

  a {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`;
