import React, { useEffect, useState } from 'react';
import Markdown from 'markdown-to-jsx';

import { AboutStyles, VideoContainer } from './About.styled';
import Skeleton from './Skeleton/Skeleton';

const About = ({ about }) => (
  <AboutStyles className="section" id="about">
    <div className="container">
      <h2>{about?.title.es}</h2>
      <div className="content">
        <div className="introduction">
          <Markdown>{about?.description.es}</Markdown>
        </div>
        <div className="video">
          <VideoContainer>
            {/* <Skeleton /> */}
            <iframe
              width="560"
              height="315"
              srcDoc="<style>*{padding:0;margin:0;overflow:hidden}
                html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}
                span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}
                </style><a href=https://www.youtube.com/embed/421Ogq4dE_Y?autoplay=1>
                <img src=https://img.youtube.com/vi/421Ogq4dE_Y/hqdefault.jpg alt='Conoce a Mataseguros'><span>▶</span></a>"
              loading="lazy" // Important for performance stop the loading of the iframe until it is in the viewport
              title="Conoce a MataSeguros"
              allow="autoplay; encrypted-media; picture-in-picture"
              allowFullScreen
            />
          </VideoContainer>
        </div>
      </div>
    </div>
  </AboutStyles>
);

export default About;
