import styled, { css } from "styled-components";
import { container } from "../../styles/GlobalStyles";
import { device, mediaQuery } from "../../styles/mixins";

export const AboutStyles = styled.section`
  overflow: hidden;
  font-family: "Poppins", sans-serif;
  font-family: "Inter", sans-serif;
  margin-bottom: 48px;

  .container {
    ${container}

    .content {
      display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 46px;
    }
    
    .introduction {
      max-width: 942px;
      margin: 0 auto;
    }

    .video {
      margin-left: auto;
      margin-right: auto;
      margin-top: 48px;
      flex-shrink: 0;
      max-width: 560px;
      width: 100%;

      iframe {
        width: 100%;
      }
    }
    ${mediaQuery.lg`
    .content {
      flex-wrap: nowrap;
      flex-direction: row;
      gap: 56px;
      flex: 0 0 45%;
    }

      .video {
        // margin-top: 100px;
        flex: 1 0 41%;
      }
  `}
    h2 {
      color: var(--color__primary);
      font-size: 32px;
      margin: 2rem 0 1.5rem;
      position: relative;
      line-height: 1.2;
      font-weight: 600;
      flex-basis: 100%;
      text-align: center;

      ${mediaQuery.lg`
            font-size: 2.5rem;
        margin-bottom: 0;
      `}
    }
    p {
      font-size: 1.2rem;
      margin-top: 1rem;
      line-height: 1.5;
    }

    b {
      font-weight: 600;
    }
  }
`;

export const VideoContainer = styled.div`
  flex-shrink: 0;
  border-radius: 10px;
  overflow: hidden;
  height: 315px;
  align-self: center;
  box-shadow: rgb(0 0 0 / 3%) 0px 1px 2px, rgb(0 0 0 / 3%) 0px 2px 4px,
    rgb(0 0 0 / 3%) 0px 4px 8px, rgb(0 0 0 / 3%) 0px 8px 16px;
  float: left; /* Ensure the video container floats to the right */
  margin-bottom: 16px; /* Add margin-bottom to ensure text wraps below the video */
  width: 100%;
`;