import React from "react";
import { Cross2Icon } from "@radix-ui/react-icons";
import * as Dialog from "@radix-ui/react-dialog";
import {
  StyledOverlay,
  StyledContent,
  IconButton,
  DialogContent,
  DialogTitle,
  DialogDescription,
} from "./Modal.styled";
import Button from "../Button";
const Modal = ({ onClose, isError }) => (
  <Dialog.Root
    defaultOpen
    onOpenChange={(value) => {
      if (!value) onClose();
    }}
  >
    {/* <Dialog.Trigger asChild>{trigger}</Dialog.Trigger> */}
    <Dialog.Portal>
      <StyledOverlay />
      <StyledContent>
        <DialogContent>
          <DialogTitle>Formulario {isError && "no"} enviado</DialogTitle>
          <DialogDescription>
            {isError
              ? "Lo siento, el formulario no se ha podido enviar. Por favor, inténtelo de nuevo."
              : "En breve un miembro de nuestro equipo se pondrá en contacto contigo."}
          </DialogDescription>
          <div style={{ marginTop: "25", justifyContent: "flex-end" }}>
            <Dialog.Close asChild>
              <Button aria-label="Close" text={isError ? "Volver" : "Vale"} />
            </Dialog.Close>
          </div>
          <Dialog.Close asChild>
            <IconButton>
              <Cross2Icon />
            </IconButton>
          </Dialog.Close>
        </DialogContent>
      </StyledContent>
    </Dialog.Portal>
  </Dialog.Root>
);

export default Modal;
