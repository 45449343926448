import styled from "styled-components";
import * as Separator from "@radix-ui/react-separator";
import { mediaQuery } from "../../styles/mixins";

export const ServicesList = styled.ul`
  flex-wrap: wrap;

  margin-top: 4rem;

  list-style: outside none none;
  /* display: grid; */
  justify-items: center;
  justify-content: center;
  gap: 3rem;
  /* grid-template-columns: repeat(2, 1fr); */
  display: flex;
  /* background-attachment: fixed;
background-color: rgb(217, 228, 245);
background-image: linear-gradient(
  315deg,
  rgb(217, 228, 245) 0%,
  rgb(245, 227, 230) 74%
); */
  font-family: "Poppins", sans-serif;

  // GRID
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(325px, 1fr));
`;

export const ServicesSummaryStyles = styled.section`
  position: relative;
  margin-bottom: 86px;
  padding-top: 32px;
  /* height: 994px; */
  .container {
    position: relative;
    z-index: 2;
    /* padding-top: 48px; */
  }
`;

export const Overlay = styled.div`
  background: rgba(0, 0, 0, 0)
    linear-gradient(to right, slateblue 0%, deepskyblue 100%) repeat scroll 0%
    0%;
  background: rgba(0, 0, 0, 0)
    linear-gradient(to right, #1e70695e 50%, #0d617a4f 100%) repeat scroll 0% 0%; // Color de los servicios de la página principal
  background-repeat: repeat;
  background-size: auto;
  clip-path: polygon(0px 0%, 210% 0%, 100% 100%, 0px 88%);
  transition: all 0.5s ease;
  position: absolute;
  inset: 0px;
  background-size: auto;
  background-repeat: no-repeat;
  will-change: transform;
  width: 100%;
  /* height: 994px; */
  /* top: -50px; */
  top: 0;
  height: 120%;

  ${mediaQuery.md`
    height: 130%;

  clip-path: polygon(0px 0%, 210% 0%, 100% 100%, 0px 75%);

  `}
  ${mediaQuery.md`
    height: 140%;


  `}
`;

export const SectionTitle = styled.h2`
  font-size: 32px;
  font-weight: 600;
  margin: 48px 0 32px;
  color: var(--color--grey);
  font-family: Untitled Sans, -apple-system, system-ui, sans-serif;
`;

export const ContactSeparator = styled(Separator.Root)`
  background-color: #fac69d;
  &[data-orientation="horizontal"] {
    height: 3px;
    width: 50%;
  }
  &[data-orientation="vertical"] {
    height: 100%;
    width: 1px;
  }
`;
