import { Link } from "gatsby";
import React from "react";
import { ServiceItemStyled } from "./ServiceItem.styled";

const ServiceItem = ({ service }) => {
  return (
    <ServiceItemStyled
      style={{
        background: `linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), url(${service.image?.asset?.url})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <Link to={`/servicio/${service.slug.current}`}>
        <h2>{service.title.es}</h2>
        {/* <p>{service.subtitle.es}</p> */}
      </Link>
    </ServiceItemStyled>
  );
};

export default ServiceItem;
