import styled from "styled-components";
import * as Dialog from "@radix-ui/react-dialog";

export const StyledContent = styled(Dialog.Content)`
  background-color: white;
  border-radius: 6px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 450px;
  max-height: 85vh;
  padding: 25px;
  z-index: 3;
  &:focus {
    outline: none;
  }
`;

export const StyledOverlay = styled(Dialog.Overlay)`
  background-color: rgba(0, 0, 0, 0.44);
  position: fixed;
  inset: 0;
  z-index: 2;
);`;

export const IconButton = styled.button`
  all: unset;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: rgb(87, 70, 175);
  position: absolute;
  top: 10px;
  right: 10px;

  &:hover {
    background-color: rgb(237, 233, 254);
  }
  &:focus {
    box-shadow: rgb(196 184 243) 0px 0px 0px 2px;
  }
`;

export const DialogContent = styled(Dialog.Content)`
  background-color: white;
  border-radius: 6px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 450px;
  max-height: 85vh;
  padding: 25px;
  &:focus {
    outline: none;
  }
`;

export const DialogTitle = styled(Dialog.Title)`
  font-family: "Untitled Sans", -apple-system, system-ui, sans-serif;
  margin: 0;
  font-weight: 500;
  color: rgb(26, 21, 35);
  font-size: 17px;
`;

export const DialogDescription = styled(Dialog.Description)`
  font-family: "Untitled Sans", -apple-system, system-ui, sans-serif;
  margin: 10px 0 20px;
  color: rgb(111, 110, 119);
  font-size: 15px;
  line-height: 1.5;
`;
