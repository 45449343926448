import { Link } from "gatsby";
import React from "react";

import efficiency from "../../assets/images/efficiency.jpg";
import optimization from "../../assets/images/optimization.jpg";
import peritaje from "../../assets/images/peritaje.jpg";
import solar from "../../assets/images/solar.jpg";
import wind from "../../assets/images/wind.jpg";

import ServiceItem from "./ServiceItem";

import {
  ServicesList,
  ServicesSummaryStyles,
  Overlay,
  SectionTitle,
  ContactSeparator,
} from "./Services.styled";

export default function Services({ services }) {
  return (
    <ServicesSummaryStyles id="services">
      <Overlay></Overlay>
      <div className="container">
        <SectionTitle>Servicios</SectionTitle>
        <ContactSeparator />
        <ServicesList>
          {services.map((service, i) => (
            <ServiceItem service={service} key={i} />
          ))}
        </ServicesList>
      </div>
    </ServicesSummaryStyles>
  );
}
