import styled, { keyframes } from "styled-components";

const loading = keyframes`
	0% {transform:translateX(-100%);}
	100% {transform:translateX(100%);}
`;

export const Container = styled.div`
  height: 315px;
  /* width: 560px; */
  background: #eee;
  position: relative;

  overflow: hidden;
  flex-shrink: 0;
  position: relative;
  border-radius: 10px;

  // Animation

  &::after {
    content: "";
    top: 0;
    transform: translateX(100%);
    width: 560px;
    height: 315px;
    position: absolute;
    z-index: 1;
    animation: ${loading} 1s infinite 3s;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.8) 50%,
      rgba(250, 286, 232, 0) 99%,
      rgba(125, 185, 232, 0) 100%
    ); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#007db9e8',GradientType=1 );
  }
`;

export const PlayIcon = styled.svg`
  path:first-of-type {
    fill: rgba(0, 0, 0, 0.2);
  }
`;

export const IconWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  width: 68px;
  height: 48px;
  margin-left: -34px;
  margin-top: -24px;
`;
